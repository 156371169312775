import Styled from "styled-components";

export const MortageForm = Styled.form`
    width: 100%;
    padding: 40px;
    box-shadow: ${({ theme }) => `0 0 28px -3px ${theme.black}`};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    grid-template-areas:
    "homeValue downPayment mortgage-output mortgage-output"
    "loanAmount loanAmount mortgage-output mortgage-output"
    "interestRate loanTerm mortgage-output mortgage-output"
    "startDateMonth startDateYear mortgage-output mortgage-output"
    "propertyTax pmi mortgage-output mortgage-output"
    "homeIns monthlyHoa mortgage-output mortgage-output"
    "loanType options mortgage-output mortgage-output"
    "calculate calculate calculate calculate";
    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "homeValue downPayment"
        "loanAmount loanAmount"
        "interestRate loanTerm"
        "startDateMonth startDateYear"
        "propertyTax pmi"
        "homeIns monthlyHoa"
        "loanType options"
        "calculate calculate"
        "mortgage-output mortgage-output";
    }
`;
export const MortageOutput = Styled.div`
    grid-area: mortgage-output;
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: 2rem;
    grid-template-areas:
    "summary-title summary-title";
    h3 {
        text-align: center;
        grid-area: summary-title;
        font-family: ${({ theme }) => theme.fontSecondary};
        margin: 0;
    }
    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
export const Output = Styled.h4`
    ${({ gridArea }) => (gridArea ? `grid-area: ${gridArea}` : null)};
    font-family: ${({ theme }) => theme.fontSecondary};
    color: ${({ theme }) => theme.black};
    margin: 0;
    display: flex;
    flex-direction: column;
    span {
        margin-top: 1rem;
        color: ${({ theme }) => theme.grey};
    }
`;
export const ContactForm = Styled.form`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: center;
    fieldset {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 2rem;
    }
    button {
        width: 100%;
    }
`;
export const SearchForm = Styled.form`
    width: 100%;
    padding: 40px;
    box-shadow: ${({ theme }) => `0 0 28px -3px ${theme.black}`};
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2rem;
    grid-template-areas:
    "location location type type sortBy sortBy"
    "bedrooms baths minPrice maxPrice searchNow searchNow";
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "location location"
        "type type"
        "sortBy sortBy"
        "bedrooms baths"
        "minPrice maxPrice"
        "searchNow searchNow";
    }
`;
export const SubscribeForm = Styled.form`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        fieldset {
            width: 100%;
            margin-right: 0px;
        }
        button {
            width: 100%;
        }
    }
`;
export const Fieldset = Styled.fieldset`
    width: 50%;
    position: relative;
    border: none;
    padding: 0px;
    margin-right: 1rem;
`;
export const Input = Styled.input`
    width: 100%;
    font-family: ${({ theme }) => theme.fontSecondary};
    height: 52px;
    border: 1px solid rgb(226, 226, 226);
    background-color: transparent;
    &:focus {
        outline: 0;
        border: ${({ theme }) => `1px solid ${theme.black}`};
    }
`;
export const TextArea = Styled.textarea`
    width: 100%;
    font-family: ${({ theme }) => theme.fontSecondary};
    &::placeholder {
        color: ${({ theme }) => theme.grey};
    }
    height: 150px;
    padding-left: 13px;
    padding-top: 13px;
    resize: vertical;
    border: 1px solid rgb(226, 226, 226);
    background-color: transparent;
    &:focus {
        outline: 0;
        border: ${({ theme }) => `1px solid ${theme.black}`};
    }
`;
export const Span = Styled.span`
    color: red;
`;
export const Label = Styled.label`
    user-select: none;
    font-family: ${({ theme }) => theme.fontSecondary};
    color: ${({ theme }) => theme.grey};
    position: absolute;
    top: calc(52px / 3);
    left: 13px;
    font-weight: 300;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    ${Input}:focus ~ &, ${Input}:not(:placeholder-shown) ~ & {
        color: ${({ theme }) => theme.black};
        font-weight: 700;
        font-size: 1rem;
        transform: translate3d(-13px, -40px, 0);
    }
`;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const Success = Styled.span`
    margin-right: 5px;
    padding: 0.35em 1.2em;
    color: #155724 !important;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;
export const Error = Styled.span`
    margin-right: 5px;
    padding: 0.35em 1.2em;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
`;
